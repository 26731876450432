import React, { useState } from 'react';
import Confetti from './Confetti';
import BotIcon from './assets/AgileLogo.svg';
import UserIcon from './assets/User.svg';
import ChatWidget from './ChatWidget';

function App() {
  /*
  const [input, setInput] = useState('');
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // New state for loading indicator

  const handleAIResponse = (response) => {
    var confetti = new Confetti();

    while (response.includes('[CONFETTI]')) {
      confetti.moreConfetti();
      response = response.replace('[CONFETTI]', '');
    }
    
    while (response.includes('[MORECONFETTI]')) {
      confetti.moreConfetti();
      response = response.replace('[MORECONFETTI]', '');
    }
    
    addMessage({ type: 'ai', text: response });    
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userInput = input.trim();
    if (!userInput) return;

    setInput(''); // Clear input field
    addMessage({ type: 'user', text: userInput });

    setIsLoading(true); // Start loading

    try {
      const response = await fetch('http://widgethoster.sytes.net/api/get_ai_response', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ input: userInput }),
      });
      const data = await response.json();

      handleAIResponse(data.response);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoading(false); // Stop loading regardless of success or error
    }
  };

  const addMessage = (message) => {
    setMessages((prevMessages) => [...prevMessages, message]);
  };

  // needed if we want to render code blocks
  const renderMessage = (msg) => {
    const codeBlockRegex = /```(.*?)```/gs;
    const parts = msg.text.split(codeBlockRegex);

    return parts.map((part, index) => {
      if ((index % 2) === 1) {
        return <pre key={index}><code>{part}</code></pre>;
      } else {
        return <span key={index}>{part}</span>;
      }
    });
  };

  return (
    <div className="chat-container">
      <div className="messages">
      {messages.map((msg, index) => (
        <div key={index} className={`message ${msg.type}`}>
          <div className="icon-container">
            {msg.type === 'ai' ? 
              <div className="icon-background ai-background"><img src={BotIcon} alt="Bot" /></div> : 
              <div className="icon-background user-background"><img src={UserIcon} alt="User" /></div>}
          </div>
          <div className="message-text">
            {renderMessage(msg)}
          </div>
        </div>
      ))}
      {isLoading && <div className="dot-pulse">Loading...</div>} 
      </div>
      <form onSubmit={handleSubmit} className="message-form">
        <input
          className="search_input"
          type="text"
          value={input}
          disabled={isLoading} // Disable input when loading
          onChange={(e) => setInput(e.target.value)}
          placeholder="Type a message..."
        />
        <button className="button-17" type="submit" disabled={isLoading}>Send</button>
      </form>
      <ChatWidget />
    </div>
  );
  */
 return (
  <div>
    <ChatWidget />
  </div>
  )
}

export default App;
