import React, { useState } from 'react';
import './ChatWidget.css';
import confetti from "canvas-confetti";
import BotIcon from './assets/AgileLogo.svg';
import UserIcon from './assets/User.svg';

function ChatWidget() {
    const [input, setInput] = useState('');
    const [messages, setMessages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isWidgetOpen, setIsWidgetOpen] = useState(true);

    const toggleWidget = () => {
        setIsWidgetOpen(!isWidgetOpen);
    };

  
    const handleAIResponse = (response) => {
      var confetti = new Confetti();
  
      while (response.includes('[CONFETTI]')) {
        confetti.moreConfetti();
        response = response.replace('[CONFETTI]', '');
      }
      
      while (response.includes('[MORECONFETTI]')) {
        confetti.moreConfetti();
        response = response.replace('[MORECONFETTI]', '');
      }
      
      addMessage({ type: 'ai', text: response });    
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      const userInput = input.trim();
      if (!userInput) return;
  
      setInput(''); // Clear input field
      addMessage({ type: 'user', text: userInput });
  
      setIsLoading(true); // Start loading
  
      try {
        const response = await fetch('https://widgethoster.sytes.net/api/get_ai_response', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ input: userInput }),
        });
        const data = await response.json();
  
        handleAIResponse(data.response);
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setIsLoading(false); // Stop loading regardless of success or error
      }
    };
  
    const addMessage = (message) => {
      setMessages((prevMessages) => [...prevMessages, message]);
    };
  
    // needed if we want to render code blocks
    const renderMessage = (msg) => {
      const codeBlockRegex = /```(.*?)```/gs;
      const parts = msg.text.split(codeBlockRegex);
  
      return parts.map((part, index) => {
        if ((index % 2) === 1) {
          return <pre key={index}><code>{part}</code></pre>;
        } else {
          return <span key={index}>{part}</span>;
        }
      });
    };

  return (
    <div className={`chat-widget ${isWidgetOpen ? '' : 'collapsed'}`}>
        <button className="toggle-widget" onClick={toggleWidget}>
            {isWidgetOpen ? '▼' : '▲'}
        </button>
        {isWidgetOpen && (
        <>
        <div className="messages">
        {messages.map((msg, index) => (
            <div key={index} className={`message ${msg.type}`}>
            <div className="icon-container">
                {msg.type === 'ai' ? 
                <div className="icon-background ai-background"><img src={BotIcon} alt="Bot" /></div> : 
                <div className="icon-background user-background"><img src={UserIcon} alt="User" /></div>}
            </div>
            <div className="message-text">
                {renderMessage(msg)}
            </div>
            </div>
        ))}
            {isLoading && <div className="dot-pulse">Loading...</div>} {/* Loading indicator */}
        </div>
        <form onSubmit={handleSubmit} className="message-form">
            <input
            type="text"
            className="search_input"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Type a message..."
            disabled={isLoading}
            />
            <button type="submit" className="button-17" disabled={isLoading}>Send</button>
        </form>
        </> )}
    </div>
  );
}


class Confetti {
    throwConfetti() {
      function randomInRange(min, max) {
        return Math.random() * (max - min) + min;
      }
  
      confetti({
        angle: randomInRange(55, 125),
        spread: randomInRange(50, 70),
        particleCount: randomInRange(50, 100),
        origin: { y: 0.6 },
      });
    }
  
    moreConfetti() {
      const count = 200;
      const defaults = {
        origin: { y: 0.7 },
      };
  
      function fire(particleRatio, opts) {
        confetti({
          ...defaults,
          ...opts,
          particleCount: Math.floor(count * particleRatio),
        });
      }
  
      fire(0.25, {
        spread: 26,
        startVelocity: 55,
      });
      fire(0.2, {
        spread: 60,
      });
      fire(0.35, {
        spread: 100,
        decay: 0.91,
        scalar: 0.8,
      });
      fire(0.1, {
        spread: 120,
        startVelocity: 25,
        decay: 0.92,
        scalar: 1.2,
      });
      fire(0.1, {
        spread: 120,
        startVelocity: 45,
      });
    }
}
  
  export default ChatWidget;